import * as React from 'react';
import styled from '../../styled-components';
export const LogoImgEl = styled.img.withConfig({
  componentId: "sc-1ypz5kk-0"
})(["max-height:", ";max-width:", ";width:100%;display:block;"], props => props.theme.logo.maxHeight, props => props.theme.logo.maxWidth);
export const LogoWrap = styled.div.withConfig({
  componentId: "sc-1ypz5kk-1"
})(["text-align:center;"]);
const Link = styled.a.withConfig({
  componentId: "sc-1ypz5kk-2"
})(["display:inline-block;"]);
export const LinkWrap = url => Component => <Link href={url}>{Component}</Link>;